import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { saveClient } from '../../../services/cliente.service';
import { LoadingMovilGoRegistro } from '../../../shared/components/loadingMovilGo';

const DatosCliente = ({cliente}) => {
    //console.log("cliente...", cliente);
    const {cedula, nombre,apellido, direccion, correo, celular} = cliente
   

    
    

    return (
        <>
            <div>
                <div style={{ textAlign: 'center' }}>
                    DATOS PERSONALES
                </div>
                <div >
                    <Form.Group className={'defaultTitle'}>
                        <Form.Label>Cédula</Form.Label>
                        <Form.Control value={cedula} disabled={true} />
                        
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control value={nombre}  disabled={true} />
                        
                        <Form.Label>Apellido</Form.Label>
                        <Form.Control value={apellido} disabled={true} />
                        
                        <Form.Label>Direccion</Form.Label>
                        <Form.Control value={direccion} disabled={true} />
                        
                        <Form.Label>Correo Electronico</Form.Label>
                        <Form.Control value={correo} disabled={true}  />
                       
                        <Form.Label >Celular</Form.Label>
                        <Form.Control value={celular} disabled={true} />
                        
                    </Form.Group>
                </div>
            </div>
        </>
    );
}

export default DatosCliente;