import React, { useEffect, useState } from 'react';
import { Card, Button, Container, Modal } from 'react-bootstrap';
import Slider from 'react-slick';
import { HomeWrapper } from '../../../shared/components/style';
import { idCardosoVendedor, idMobilgoVendedor } from '../../../utilities/odoo-config';
import { Desencrypt } from '../../../utilities/cipher';
import { FormatMoney } from '../../../utilities/formats';
import ArrowUp from '../../../assets/ArrowUp.png';
import ArrowDown from '../../../assets/ArrowDown.png';
import './styles.css';

const CustomPrevArrow = (props) => (
    <button {...props} className="custom-prev-arrow">
        <img src={ArrowUp} alt="Prev Arrow" style={{ width: '30px', height: '30px' }} />
    </button>
);

const CustomNextArrow = (props) => (
    <button {...props} className="custom-next-arrow">
        <img src={ArrowDown} alt="Next Arrow" style={{ width: '30px', height: '30px' }} />
    </button>
);

const CarruselRifas = ({ rifas = [], onCompraRealizada }) => {
    const [cards, setCards] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleOpenModal = (image) => {
        setSelectedImage(image);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedImage(null);
    };

    const capturarRifa = async (id, name, sorteo, precio, imagen, porcentaje_recaudo, resolucion, operador_efectivo, operador_tarjeta) => {
        localStorage.setItem('datosRifa', JSON.stringify({ id, name, sorteo, precio, imagen, porcentaje_recaudo, resolucion, operador_efectivo, operador_tarjeta }));
        onCompraRealizada();
    };

    const size = useWindowSize();
    const [numberCards, setNumberCards] = useState(1);

    function useWindowSize() {
        const [windowSize, setWindowSize] = useState({ width: undefined, height: undefined });

        useEffect(() => {
            function handleResize() {
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }
            window.addEventListener('resize', handleResize);
            handleResize();

            return () => window.removeEventListener('resize', handleResize);
        }, []);

        return windowSize;
    }

    useEffect(() => {
        if (size.width <= 768 && size.height <= 1024) {
            setNumberCards(1);
        } else if (size.width >= 768 && size.height >= 800) {
            setNumberCards(1);
        }
    }, [size]);

    const settings = {
        arrows: true,
        dots: false,
        accessibility: true,
        infinite: false,
        speed: 300,
        slidesToShow: numberCards,
        vertical: true,
        verticalSwiping: true,
        adaptiveHeight: true,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
    };

    useEffect(() => {
        const ajustarRifas = (array) => {
            if (array.length > 1) {
                // Intercambiar las posiciones [0] y [1] si hay más de una rifa
                [array[0], array[1]] = [array[1], array[0]];
            }
            return array;
        };

        let aux = ajustarRifas(
            rifas.filter((element) => element.numero_resolucion_rifa !== false)
        ).map((element) => {
            const datoSession = Desencrypt();
            const mostrarAbono = datoSession.id === idCardosoVendedor || datoSession.id === idMobilgoVendedor ? false : true;

            return (
                <Card key={element.id} className="mb-3 card" border="info">
                    <Card.Header>{element.name}</Card.Header>
                    <Card.Body>
                        <div className="cardImgContainer" style={{ position: 'relative' }}>
                            {element.image_medium ? (
                                <Card.Img
                                    src={'data:image/jpeg;base64,' + element.image_medium}
                                    className="card-img cardImg"
                                    alt="Imagen de la tarjeta"
                                />
                            ) : (
                                <div className="no-image-placeholder">Imagen no disponible...</div> // Mensaje o imagen de reemplazo
                            )}
                            <Button
                                variant="light"
                                style={{
                                    position: 'absolute',
                                    top: '2px',
                                    right: '2px',
                                    borderRadius: '50%',
                                    padding: '0.5rem',
                                    border: 'none',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                onClick={() => handleOpenModal(element.image_medium)}
                            >
                                🔍
                            </Button>
                        </div>
                    </Card.Body>

                    <Card.Footer className="text-muted cardFooter">
                        <div className="cardContent">
                            <p className="textCard">
                                {element.fecha_sorteo}
                                <br />
                                {FormatMoney(element.precio)}
                            </p>
                            <Button
                                variant="info"
                                block
                                onClick={() =>
                                    capturarRifa(
                                        element.id,
                                        element.name,
                                        element.fecha_sorteo,
                                        element.precio,
                                        element.image_medium,
                                        element.porcentaje_recaudo,
                                        element.numero_resolucion_rifa,
                                        element.operador_efectivo,
                                        element.operador_tarjeta
                                    )
                                }
                            >
                                Comprar
                            </Button>
                        </div>
                    </Card.Footer>
                </Card>
            );
        });

        setCards(aux);
    }, [rifas]);

    return (
        <Container>
            <HomeWrapper>
                <div className="clearfix mt-1 mb-2"></div>
                <Slider {...settings}>{cards}</Slider>

                <Modal show={showModal} onHide={handleCloseModal} centered>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body >
                        {selectedImage && (
                            <img
                                src={'data:image/jpeg;base64,' + selectedImage}
                                alt="Imagen en modal"
                                style={{ width: '100%', height: 'auto', display: 'block' }}
                            />
                        )}
                    </Modal.Body>
                </Modal>
            </HomeWrapper>
        </Container>
    );
};

export default CarruselRifas;
