import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { saveClient } from '../../../services/cliente.service';
import { LoadingMovilGoRegistro } from '../../../shared/components/loadingMovilGo';

const RegistroCliente = ({ id_product, setCliente, setParther, closeRegistrer }) => {
    const [cedula, setCedula] = useState('');
    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [direccion, setDireccion] = useState('');
    const [email, setEmail] = useState('');
    let correo = /^[^@\s]+@[^@\.\s]+(\.[^@\.\s]+)+$/;
    const [celular, setCelular] = useState('');
    const [opacity, setOpacity] = useState(1);
    const [loading, setLoading] = useState(false);
    const [emailValid, setEmailValid] = useState(false);
    const [errors, setErrors] = useState({
        'cedula': '',
        'nombre': '',
        'apellido': '',
        'email': '',
        'celular': '',
        'direccion': '',
    });

    const saved = (response) => {
        if (response) {
            setCliente(cedula, nombre, apellido, direccion, email, celular, response.id);
            setParther(response.id);
            setLoading(false);
            setOpacity(1)
            closeRegistrer(true);
        }
        cleanFields()
    }

    const cleanFields = () => {
        setCedula('');
        setNombre('');
        setApellido('');
        setDireccion('');
        setEmail('');
        setCelular('');
    }

    const registrarCliente = () => {
        if (!viriryFile()) {
            return
        }
        const client = {
            'x_name1': nombre.split(' ')[0],
            'x_name2': nombre.split(' ')[1] ?? '',
            'x_lastname1': apellido.split(' ')[0],
            'x_lastname2': apellido.split(' ')[1] ?? '',
            'mobile': celular,
            'email': email,
            'direccion': direccion,
            'xidentification': cedula,
            product_id: id_product
        }
        setLoading(true);
        setOpacity(0.4)
        saveClient(client, saved);
    }

    const viriryFile = () => {
        const emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        const nameValid = nombre.match(/[^a-zA-Z0-9\s/]/)
        const lastValid = apellido.match(/[^a-zA-Z0-9\s/]/)
        let flag = true;
        const keys = [
            'cedula',
            'nombre',
            'apellido',
            'celular',
            'direccion',
            'email',
        ]
        const errors = {};
        keys.forEach(element => {
            if (element === 'email' && (!emailValid && email !== '')) {
                flag = false;
                setEmailValid(true);
                errors[element] = "El correo en incorrecto"
            } else if (element === 'nombre' && nameValid) {
                flag = false;
                errors[element] = "El nombre no debe llevar tildes ni caracteres especiales"
            } else if (element === 'apellido' && lastValid) {
                flag = false;
                errors[element] = "El apellido no debe llevar tildes ni caracteres especiales"
            } else if (element.trim() === '') {
                flag = false;
                errors[element] = "Este campo es obligatorio"
            } else {
                errors[element] = ""
            }
        });
        setErrors(errors)
        return flag;



    }


    return (
        <>
            <div>
                <div style={{ textAlign: 'center' }}>
                    {loading && <LoadingMovilGoRegistro />}
                </div>
                <div style={{ opacity: opacity }}>
                    <Form.Group className={'defaultTitle'}>
                        <Form.Label>Cédula</Form.Label>
                        <Form.Control
                            value={cedula}
                            onChange={(event) => {
                                const value = event.target.value;
                                // Solo permitir hasta 10 caracteres y convertir a string
                                if (value.length <= 10) {
                                    setCedula(String(value));
                                }
                            }}
                            onBlur={viriryFile}
                            type="number"
                            placeholder="Cédula"
                        />
                        <p style={{ fontSize: 12, color: 'red' }}>{errors.cedula} <br></br></p>
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control value={nombre} onChange={(event) => setNombre(event.target.value)} onBlur={viriryFile} type="text" placeholder="Nombre" />
                        <p style={{ fontSize: 12, color: 'red' }}>{errors.nombre} <br></br></p>
                        <Form.Label>Apellido</Form.Label>
                        <Form.Control value={apellido} onChange={(event) => setApellido(event.target.value)} onBlur={viriryFile} type="text" placeholder="Apellido" />
                        <p style={{ fontSize: 12, color: 'red' }}>{errors.apellido} <br></br></p>
                        <Form.Label>Dirección</Form.Label>
                        <Form.Control value={direccion} onChange={(event) => setDireccion(event.target.value)} onBlur={viriryFile} type="text" placeholder="Direccion" />
                        <p style={{ fontSize: 12, color: 'red' }}>{errors.direccion} <br></br></p>
                        <Form.Label>Correo Electrónico</Form.Label>
                        <Form.Control value={email} onChange={(event) => setEmail(event.target.value)} onBlur={viriryFile} type="email" placeholder="Correo Electonico(Opcional)" />
                        <p style={{ fontSize: 12, color: 'red' }}>{errors.email} <br></br></p>
                        <Form.Label >Celular</Form.Label>
                        <Form.Control value={celular} onChange={(event) => setCelular(event.target.value)} onBlur={viriryFile} type="number" placeholder="Celular" />
                        <p style={{ fontSize: 12, color: 'red' }}>{errors.celular} <br></br></p>
                    </Form.Group>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                <Button
                    variant="info"
                    onClick={() => registrarCliente()}
                    disabled={!cedula || !nombre || !apellido || !direccion || !celular || (!correo.test(email) ? email : "")}
                >
                    Registar
                </Button>
            </div>
        </>
    );
}

export default RegistroCliente;